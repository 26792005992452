
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    isDetail: {
      default: false,
      type: Boolean
    },
    isNotAllProduct: {
      default: false,
      type: Boolean
    }
  }
})
export default class OtopackWarning extends Vue {
  props: any = this.$props;
  get warning() {
    if (this.props.isNotAllProduct) {
      return "Biaya Pengiriman untuk produk yang Anda pilih tidak termasuk Asuransi";
    } else {
      return "Biaya Pengiriman untuk produk OTOPACK150 dan OTOPACK250 tidak termasuk Asuransi";
    }
  }
}
